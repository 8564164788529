/*
 * MODULE LOADER
 */

export function load(modules) {
  modules.forEach(module => {
    if (module.init) {
      module.init();
    }
  });

  document.addEventListener('DOMContentLoaded', e => {
    modules.forEach(module => {
      
      if (module.mediaQuery) {

        const mq = window.matchMedia(module.mediaQuery);

        if (mq.matches && module.bootstrap) {
          module.bootstrap();
        }

        mq.addEventListener('change', e => {
          if (e.matches && module.bootstrap) {
            module.bootstrap();
          } else if (!e.matches && module.destroy) {
            module.destroy();
          }
        });

      } else if (module.bootstrap) {
        module.bootstrap();
      }

    });
  });
}