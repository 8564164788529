import { animate, scroll } from 'motion';

export function bootstrap() {

  // Parallax animation
  // document.querySelectorAll('.c-home-grid__feature').forEach(featureEl => {
  //   scroll(
  //     animate(
  //       featureEl.querySelector('figure'),
  //       { y: ['-80%', '80%'] },
  //       { ease: 'linear' }
  //     ),
  //     {
  //       target: featureEl,
  //       offset: ['-80% end', '180% start'],
  //       allowWebkitAcceleration: true
  //     }
  //   )
  // });

  // 'Bring to front' button
  document.querySelectorAll('.c-vfigure__button').forEach(btn => {
    btn.addEventListener('click', e => {
      btn.parentNode.classList.toggle('c-vfigure--toFront');
    });
  });
  
}