/* 
 * GO-TO-TOP BUTTON
 */

import { scrollThreshold, removeScrollThreshold } from "../shared/scrollThreshold";

let scrollListener;

export function bootstrap() {
  const button = document.querySelector('.js-gototop'),
        scroller = document,
        target = document.scrollingElement;

  scrollListener = scrollThreshold(state => {
    if (state) {
      button.classList.remove('u-hidden');
    } else {
      button.classList.add('u-hidden');
    }
  }, 1000);

  button.addEventListener('click', e => {
    target.scrollTo(0, 0);
  });
}

export function destroy() {
  removeScrollThreshold(scrollListener);
}