export function scrollThreshold(callback, threshold, scroller = document, target = document.scrollingElement) {
  if (scroller !== document && target === document.scrollingElement) {
    target = scroller;
  }

  let state = false, previousState = false;
  const scrollCallback = () => {
    state = target.scrollTop > threshold || target.scrollLeft > threshold;

    if (state !== previousState) {
      requestAnimationFrame(() => {
        callback.call(null, state, target);
      });
    }

    previousState = state;
  };

  scroller.addEventListener('scroll', scrollCallback, { 'passive': true });

  return { scroller, scrollCallback };
}

export function removeScrollThreshold(obj) {
  obj.scroller.removeEventListener(obj.scrollCallback);
}